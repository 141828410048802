.basic-single .css-yk16xz-control,
.basic-single .css-1pahdxg-control {
  min-height: 28.8px !important;
  border-color: #ced4da !important;
}

.basic-single .css-1okebmr-indicatorSeparator {
  margin-bottom: 8px !important;
}

.basic-single .css-1uccc91-singleValue {
  top: 44% !important;
}

.custom-link,
.custom-link:hover,
.custom-link:active {
  color: inherit;
  text-decoration: none;
}

.custom-link-underline,
.custom-link-underline:hover {
  color: inherit;
  text-decoration: underline;
}

.full-width-table {
  width: -webkit-max-content;
  width: max-content;
  table-layout: auto;
}

.fa-xs {
  font-size: 6px !important;
}

@media print {
  body * {
    visibility: hidden;
  }

  .print-content,
  .print-content * {
    visibility: visible;
  }

  .print-content {
    position: absolute;
    left: 0;
    top: 0;
  }

  .print-btn {
    display: none;
  }
}

.select__indicators {
  max-height: 27.6px;
}
.css-b8ldur-Input , .css-1g6gooi{
  margin: 0 2px !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.totate-0 {
  transform: rotate(0deg);
}

.totate-0,
.rotate-90 {
  transition: transform 0.3s ease;
}
